import { postPageOpened } from '@wix/bi-logger-blog-app-uou/v2';
import {
  categoryIds,
  isPublished,
  paidPlanIds,
  paidPlansHasAccess,
} from '../../external/common/bi-events/event-helpers';
import { getBlogFeedSectionUrl } from '../../external/common/services/get-section-url';
import { NormalizedPost } from '../../external/common/types';
import { AppData } from '../../viewer.app';
import blocksModel from './model';
import { POST_PAGE_SELECTORS } from './post-page-constants';

export default blocksModel.createController(
  ({ appData, flowAPI, controllerConfig, $w }) => ({
    pageReady: async () => {
      const { wixCodeApi } = controllerConfig;
      const { isViewer } = flowAPI.environment;
      const multiStateBox = $w(
        `#${POST_PAGE_SELECTORS.POST_PAGE_MULTISTATE_BOX}`,
      );

      try {
        const { post, readingSessionId, seoTags } = await (
          appData as AppData
        ).fetchPostPageRenderModel();

        const currentLanguage =
          wixCodeApi.window.multilingual.currentLanguage ||
          (wixCodeApi.site.language ?? 'en');

        if (post.language !== currentLanguage) {
          const translation = post.translations?.find(
            (t) => t.language === currentLanguage,
          );

          if (translation?.url) {
            const { base, path } = translation.url;
            if (base && path) {
              const { searchParams } = new URL(wixCodeApi.location.url);
              const url = `${base}${path}`;
              return wixCodeApi.location.to?.(
                searchParams.size > 0
                  ? `${url}?${searchParams.toString()}`
                  : url,
              );
            }
          } else {
            throw new Error('Post language does not match current language', {
              cause: 404,
            });
          }
        }

        flowAPI.bi?.report(
          postPageOpened({
            reading_session_id: readingSessionId,
            // @ts-expect-error
            referrer: flowAPI.controllerConfig.wixCodeApi.window.referrer,
            post_stable_id: post.id,
            paid_plan_has_access: paidPlansHasAccess(post) || undefined,
            paid_plan_id: paidPlanIds(post),
            category_ids: categoryIds(post),
            is_published: Boolean(isPublished(post)),
          }),
        );

        wixCodeApi.seo.renderSEOTags(seoTags);
      } catch (error) {
        if (!isViewer) {
          return;
        }

        wixCodeApi.seo.setSeoStatusCode(404);
        multiStateBox.changeState(POST_PAGE_SELECTORS.NOT_FOUND);
        $w(`#${POST_PAGE_SELECTORS.SEE_MORE_POSTS_BUTTON}`).link =
          getBlogFeedSectionUrl(wixCodeApi);
        $w(`#${POST_PAGE_SELECTORS.SEE_MORE_POSTS_BUTTON}`).target = '_self';
      }
    },
    exports: {
      ...blocksModel.getExports(),
      getPost: async (): Promise<NormalizedPost> => {
        const { post } = await (appData as AppData).fetchPostPageRenderModel();
        return post;
      },
    },
  }),
);
