import { Node_Type, RichContent } from 'ricos-schema';
import type { Post } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import {
  paywallDemoRichContent,
  returnDemoRichContentForPaywall,
} from './demo-rich-content';
import { postContentElementIds } from './elementIds';
import model from './model';

const getPostRichContentData = async ({
  isEditor,
  post,
  state,
}: {
  isEditor: boolean;
  post: Post;
  state: $w.State;
}) => {
  let content = post.richContent as RichContent;

  if (isEditor) {
    const showPaywall =
      state.id === postContentElementIds.paidContentId ? true : false;
    if (showPaywall) {
      const paragraphs = content?.nodes.filter(
        ({ type }) => type === Node_Type.PARAGRAPH,
      );

      if (paragraphs.length) {
        const firstParagraph = paragraphs?.[0];
        const text = firstParagraph?.nodes?.[0]?.textData?.text ?? '';
        content = returnDemoRichContentForPaywall(text);
      } else {
        content = { nodes: [], metadata: { version: 1 } };
      }
    }

    return {
      richContent: content,
      paywallRichContent: showPaywall ? paywallDemoRichContent : undefined,
    };
  }

  return {
    richContent: post.richContent,
    paywallRichContent: post.paywallRichContent,
  };
};

export default model.createController(({ $w, flowAPI, appData }) => {
  let isPageReady = false;
  return {
    pageReady: async () => {
      if (isPageReady) {
        return;
      }

      isPageReady = true;

      const richContentViewer = $w(
        `#${postContentElementIds.richContentViewerId}`,
      ) as $w.RichContentViewer;

      const richContentViewerWithinPaidContent = $w(
        `#${postContentElementIds.richContentViewerWithinPaidContentId}`,
      ) as $w.RichContentViewer;

      const paywallRichContentViewer = $w(
        `#${postContentElementIds.paywallRichContentViewerId}`,
      ) as $w.RichContentViewer;

      const multiStateBox = $w(
        `#${postContentElementIds.multiStateBoxId}`,
      ) as $w.MultiStateBox;

      const metaSiteId =
        flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId ?? '';
      const isMobileView = flowAPI.environment.isMobile;

      const { post } = await appData?.fetchPostPageRenderModel();
      const isEditor = flowAPI.environment.isEditor;

      const { richContent, paywallRichContent } = await getPostRichContentData({
        isEditor,
        post,
        state: multiStateBox.currentState,
      });

      richContentViewer.metaSiteId = metaSiteId;
      richContentViewer.isMobileView = isMobileView;
      richContentViewer.content = richContent;

      if (post.preview && !isEditor) {
        multiStateBox.changeState(postContentElementIds.paidContentId);
      }

      richContentViewerWithinPaidContent.metaSiteId = metaSiteId;
      richContentViewerWithinPaidContent.isMobileView = isMobileView;
      richContentViewerWithinPaidContent.content = richContent;

      if (paywallRichContent) {
        paywallRichContentViewer.restore();
        paywallRichContentViewer.metaSiteId = metaSiteId;
        paywallRichContentViewer.isMobileView = isMobileView;
        paywallRichContentViewer.content = paywallRichContent;
      } else {
        paywallRichContentViewer.delete();
      }
    },
    exports: {},
  };
});
